
export default function search() {
  $('.top-search button').click(function(){
    if ($('.top-search').hasClass('opened')) {
      $('.top-search').removeClass('opened');
      $('.top-search-results').html('').hide();
      $('#ts-search').val('');  
    } else {
      $('.top-search').addClass('opened');
      $('#ts-search').focus();
    }
  });

  $('#ts-search').keydown(function(e){
    window.setTimeout(processSearch, 0);
  });

  function processSearch() {
    if ($('#ts-search').val().length > 2) {
      $('.top-search button i').addClass('fa-spinner fa-spin');
      $.ajax({
        url: wp.site_url,
        data: 's=' + $('#ts-search').val(),
        type: 'GET',
        success: function(res) {
          $('.top-search-results').html(res).show();
          $('.top-search button i').removeClass('fa-spinner fa-spin');
        }
      });
    } else {
    }
  }
}