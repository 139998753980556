
export default function news() {

  var isLoading = false;

  initSearch();
  initFilter();
  initNewsRow();
  initLoadNext();
  initInfiniteScroll();

  function initInfiniteScroll() {
    $(window).on('scroll', function() {
      if ($('.load-next a').length == 0) return false;

      const pos = $(document).scrollTop();
      if ($('.load-next a').offset().top < pos + $(window).height() + 50) {
        $('.load-next a').click();
      }
    });
  }

  function initLoadNext() {
    $('.load-next a').click(function(e){
      e.preventDefault();

      if (isLoading == true) return false;

      isLoading = true;

      $('.load-next svg').addClass('fa-spin');
      
      $.ajax({
        url: $(this).attr('href'),
        success: function(res) {
          $('.load-next').remove();
          $('.list-section').append($(res).find('.list-row'));
          $('.list-section').append($(res).find('.load-next'));
          initNewsRow();
          initLoadNext();

          isLoading = false;
        }
      })
    });
  }

  function searchNews() {
    var base_url = $('.filter-section').data('base-url');
    var _filter = '';
    var _search = $('#search-news').val();
    var _filter_year = '';

    $('.filter-categories a.active').not('.year').each(function(){
      if (_filter != '') _filter += ',';  
      _filter += $(this).data('id');
    });

    $('.filter-categories a.year.active').each(function(){
      if (_filter_year != '') _filter_year += ',';  
      _filter_year += $(this).data('id');
    });

    var search_url = base_url + '?taxonomy=' + _filter + '&search=' + _search + '&news_year=' + _filter_year;

    // $('.list-section').html('<div class="loading"><i class="fal fa-sync fa-spin"></i></div>');

    $.ajax({
      url: search_url,
      success: function(res) {
        $('.list-section').html($(res).find('.list-section').html());
        initNewsRow();
        initLoadNext();
      }
    })
  }

  function initSearch() {
    $('#search-news').keydown(function(e){
      setTimeout(function(){
        if (e.keyCode == 13 || $('#search-news').val() == '') {
          searchNews();
        }
      }, 0);
    });

    $('#search-news').change(function(e){
      if ($(this).val() != '') {
        searchNews();
      }
    });
  }

  function initFilter() {
    $('.filter-section .filter a').click(function(e){
      e.preventDefault();

      $(this).find('i').toggleClass('filter-opened');

      if ( $('.filter-categories').is(':visible') ) {
        $('.filter-categories').hide();
        // $(this).find('i').html('+');
      } else {
        $('.filter-categories').show();
        $('.filter-categories > a').each(function(i){
          $(this).addClass('animated gravity-' + (i % 2 + 1));
        });
        
        // $(this).find('i').html('−');
      }
    });
    
    $('.filter-categories a').not('.has-child').click(function(e){
      e.preventDefault();
      $(this).toggleClass('active');
      searchNews();
    });
    
    $('.filter-categories > a.has-child').click(function(e){
      e.preventDefault();
    
      $('.filter-categories > a.has-child').not(this).find('i').removeClass('fa-minus').addClass('fa-plus');
      $(this).find('i').toggleClass('fa-plus').toggleClass('fa-minus');
      $(this).toggleClass('show');
    
      $('.filter-categories__children').not('#children-' + $(this).data('id')).hide();

      if ($('#children-' + $(this).data('id')).is(':visible')) {
        $('#children-' + $(this).data('id')).hide();
      } else {
        $('#children-' + $(this).data('id')).show();
        $('#children-' + $(this).data('id')).find('> a').each(function(i){
          // $(this).addClass('animated gravity-' + (i % 2 + 1));
          $(this).addClass('animated fadeInDown');
        });
      }
      
    });
  }

  function initNewsRow() {
    $('.news-container .list-row').not('.rendered').each(function(){

      var thumb_height = 0;
      var thumb_height_h1 = 0;
      var thumb_height_h2 = 0;

      $(this).find('.item__thumb').each(function(i){
        var height = $(this).height();
        thumb_height = thumb_height < height ? height : thumb_height; 
        if (i < 2) thumb_height_h1 = thumb_height_h1 < height ? height : thumb_height_h1; 
        if (i >= 2) thumb_height_h2 = thumb_height_h2 < height ? height : thumb_height_h2; 
      });

      if ($(window).width() >= 768) {
        $(this).find('.item__thumb').height(thumb_height);
      } else {
        $(this).find('.item__thumb:eq(0)').height(thumb_height_h1);
        $(this).find('.item__thumb:eq(1)').height(thumb_height_h1);
        $(this).find('.item__thumb:eq(2)').height(thumb_height_h2);
        $(this).find('.item__thumb:eq(3)').height(thumb_height_h2);
      }
      

      $(this).addClass('rendered');
    });
  }
}