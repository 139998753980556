import * as ScrollMagic from "scrollmagic";
import { TweenMax, TimelineMax } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

export default function artists() {

  ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

  var isLoading = false;
  var scene = [];
  var vw = $(window).width();
  var vh = $(window).height();
  var isMobile = vw < 768 ? true : false;

  initSearch();
  initFilter();
  initNewsRow();
  initLoadNext();
  initInfiniteScroll();

  function initInfiniteScroll() {
    $(window).on('scroll', function() {
      if ($('.load-next a').length == 0) return false;
      
      const pos = $(document).scrollTop();
      if ($('.load-next a').offset().top < pos + $(window).height() + 50) {
        $('.load-next a').click();
      }
    });
  }

  function initLoadNext() {
    $('.load-next a').click(function(e){
      e.preventDefault();

      if (isLoading == true) return false;

      isLoading = true;

      $('.load-next svg').addClass('fa-spin');

      $.ajax({
        url: $(this).attr('href'),
        success: function(res) {
          $('.load-next').remove();
          $('.list-section').append($(res).find('.item'));
          $('.list-section').append($(res).find('.load-next'));
          initNewsRow();
          initLoadNext();

          isLoading = false;
        }
      })
    });
  }

  function searchNews() {
    var base_url = $('.filter-section').data('base-url');
    var _filter = '';
    var _search = $('#search-news').val();

    $('.filter-categories a.active').each(function(){
      if (_filter != '') _filter += ',';  
      _filter += $(this).data('id');
    });

    var search_url = base_url + '?taxonomy=' + _filter + '&search=' + _search;

    // $('.list-section').html('<div class="loading"><i class="fal fa-sync fa-spin"></i></div>');

    $.ajax({
      url: search_url,
      success: function(res) {
        $('.list-section').html($(res).find('.list-section').html());
        initNewsRow();
        initLoadNext();
      }
    })
  }

  function initSearch() {
    $('#search-news').keydown(function(e){
      setTimeout(function(){
        // if (e.keyCode == 13 || $('#search-news').val() == '') {
        //   searchNews();
        // }
        searchNews();
      }, 0);
    });

    // $('#search-news').change(function(e){
    //   if ($(this).val() != '') {
    //     searchNews();
    //   }
    // });
  }

  function initFilter() {
    $('.filter-section .filter a').click(function(e){
      e.preventDefault();

      $(this).find('i').toggleClass('filter-opened');
      
      if ( $('.filter-categories').is(':visible') ) {
        $('.filter-categories').hide();
        // $(this).find('i').html('+');
      } else {
        $('.filter-categories').show();
        $('.filter-categories > a').each(function(i){
          $(this).addClass('animated gravity-' + (i % 2 + 1));
        });
        
        // $(this).find('i').html('−');
      }
    });
    
    $('.filter-categories a').not('.has-child').click(function(e){
      e.preventDefault();
      $(this).toggleClass('active');
      searchNews();
    });
    
    $('.filter-categories > a.has-child').click(function(e){
      e.preventDefault();
    
      $('.filter-categories > a.has-child').not(this).find('i').removeClass('fa-minus').addClass('fa-plus');
      $(this).find('i').toggleClass('fa-plus').toggleClass('fa-minus');
      $(this).toggleClass('show');
    
      $('.filter-categories__children').not('#children-' + $(this).data('id')).hide();
      
      if ($('#children-' + $(this).data('id')).is(':visible')) {
        $('#children-' + $(this).data('id')).hide();
      } else {
        $('#children-' + $(this).data('id')).show();
        $('#children-' + $(this).data('id')).find('> a').each(function(i){
          $(this).addClass('animated fadeInDown');
        });
      }
    });
  }

  function initNewsRow() {
    var controller = new ScrollMagic.Controller();

    $('.artists-container .list-section .grouped').each(function(i){
      var group_id = 'group-' + i;
      $(this).attr('id', group_id);
    });

    var durations = [];
    var group_count = $('.artists-container .list-section .grouped').length;

    var prev_top = $('.artists-container .list-section .item').last().position().top;
    for (var i = group_count - 1; i >=0; i--) {
      durations[i] = prev_top - $('#group-' + i).position().top;
      prev_top = $('#group-' + i).position().top - 120;

      $('#group-' + i).data('top', $('#group-' + i).offset().top);
      $('#group-' + i).data('duration', durations[i]);
    }

    if (isMobile) {

      var hook_pos = $('.artists-container .list-section .item').first().offset().top / vh;
      $('.artists-container .list-section .item').each(function(i){
        var id = 'item-' + i;
        $(this).attr('id', id);
        var scene = new ScrollMagic.Scene({triggerElement: '#' + id, duration: 34})
            .setClassToggle('#' + id, 'active')
            .addTo(controller);
        scene['triggerHook'](hook_pos);
      });

    } else {

      $(window).scroll(function(){
        var left = $('.artists-container .list-section .item').offset().left;
        var top = 50;
        if ($('#main').hasClass('header-show')) {
          top = 130
        }
        const pos = $(document).scrollTop();
  
        $('.artists-container .list-section .grouped').each(function(){
          var offset = pos - $(this).data('top');
          if (offset > 0 && offset < $(this).data('duration')) {
            $(this).find('.item__group').css('position', 'fixed');
            $(this).find('.item__group').css('top', top);
            $(this).find('.item__group').css('left', left);
          } else {
            $(this).find('.item__group').css('position', 'absolute');
            $(this).find('.item__group').css('top', '50%');
            $(this).find('.item__group').css('left', 0);
          }
        });
      });

    }
  }
}
