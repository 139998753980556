import * as ScrollMagic from "scrollmagic";
import { TweenMax, TimelineMax } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

export default function culture() {

  ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

  var controller = new ScrollMagic.Controller();

	// build scene
	var scene = new ScrollMagic.Scene({triggerElement: ".text-flow-start", duration: 4000})
          .setTween('.text-flow', 1, {translateX: '-100%'})
          .setPin('.text-flow')
          .addTo(controller);
  scene['triggerHook'](0);

  // var scene1 = new ScrollMagic.Scene({triggerElement: ".text-flow-start", duration: 4000})
  //         .setPin('.text-flow-start')
  //         .addTo(controller);
  // scene1['triggerHook'](0);

  // Text Pin Boxes
  var vw = $(window).width();
  var vh = $(window).height();
  var isMobile = (vw < 768) ? true : false;
  var box_h = $('.text-pin-boxes .gb-grid-column:eq(0)').height();
  var boxes_h = $('.text-pin-boxes').height() - box_h;
  var hook1 = 0;
  var hook2 = 1 - (box_h + 160) / vh;
  console.log(hook2);

  var sceneBox = [];
  var sceneDurations = [];

  $('.text-pin-boxes .gb-grid-column').each(function(i){
    sceneDurations[i] = boxes_h - $(this).position().top;
    if (!isMobile && i < 3) {
      sceneDurations[i] -= box_h + 20;
    }
    $(this).css('top', $(this).position().top);
  });

  if (!isMobile) {
    for (var i=0; i<=2; i++) {
      sceneDurations[i] -= sceneDurations[3];
      sceneDurations[i + 10] = sceneDurations[3];
    }
  }

  console.log(sceneDurations);

  $('.text-pin-boxes').height(boxes_h + box_h + 100);
  $('.text-pin-boxes').addClass('mounted');

  $('.text-pin-boxes .gb-grid-column').each(function(i){
    var box_id = 'text-pin-box-' + i;
    $(this).attr('id', box_id);

    sceneBox[i] = new ScrollMagic.Scene({triggerElement: '#' + box_id, duration: sceneDurations[i]})
                    .setPin('#' + box_id + ' .gb-container')
                    .addTo(controller);
    if (isMobile || i < 3) {
      sceneBox[i]['triggerHook'](hook1);
    } else {
      sceneBox[i]['triggerHook'](hook2);
    }

    if (!isMobile && i < 3) {
      sceneBox[i + 10] = new ScrollMagic.Scene({triggerElement: '#' + box_id, duration: sceneDurations[i + 10], offset: sceneDurations[i] + 280})
                    .setPin('#' + box_id + ' .gb-container')
                    .addTo(controller);
      sceneBox[i + 10]['triggerHook'](hook1);
    }
  });

}
