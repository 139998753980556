
export default function jobs() {

  var isLoading = false;

  initSearch();
  initFilter();
  initLoadNext();
  initInfiniteScroll();

  searchJobs();

  initMission();

  $(window).resize(initMission);

  function initInfiniteScroll() {
    $(window).on('scroll', function() {
      if ($('.load-next a').length == 0) return false;

      const pos = $(document).scrollTop();
      if ($('.load-next a').offset().top < pos + $(window).height() + 50) {
        $('.load-next a').click();
      }
    });
  }

  function initLoadNext() {
    $('.load-next a').click(function(e){
      e.preventDefault();

      if (isLoading == true) return false;

      isLoading = true;

      $('.load-next svg').addClass('fa-spin');
      
      $.ajax({
        url: $(this).attr('href'),
        success: function(res) {
          $('.load-next').remove();
          $('.list-section').append($(res).find('.list-row'));
          $('.list-section').append($(res).find('.load-next'));
          initLoadNext();

          isLoading = false;
        }
      })
    });
  }

  function searchJobs() {
    var base_url = $('.filter-section').data('base-url');
    var _filter = '';
    var _search = $('#search-jobs').val();

    $('.filter-categories__children').each(function(){
      var sub_filter = '';
      $(this).find('a.active').each(function(){
        if (sub_filter != '') sub_filter += ':';  
        sub_filter += $(this).data('id');
      });
      if (sub_filter) {
        if (_filter != '') _filter += ',';  
        _filter += sub_filter;
      }
    });

    var search_url = base_url + '?taxonomy=' + _filter + '&search=' + _search;

    // $('.list-section').html('<div class="loading"><i class="fal fa-sync fa-spin"></i></div>');

    $.ajax({
      url: search_url,
      success: function(res) {
        $('.list-section').html($(res).find('.list-section').html());
        $('.list-section').show();
        initLoadNext();
      }
    })
  }

  function initSearch() {
    $('#search-jobs').keypress(function(e){
      if (e.keyCode == 13) {
        searchJobs();
      }
    });
  }

  function initFilter() {
    $('.filter-section .filter a').click(function(e){
      e.preventDefault();

      $(this).find('i').toggleClass('filter-opened');

      if ( $('.filter-categories').is(':visible') ) {
        $('.filter-categories').hide();
        // $(this).find('i').html('+');
      } else {
        $('.filter-categories').show();
        $('.filter-categories > a').each(function(i){
          $(this).addClass('animated gravity-' + (i % 2 + 1));
        });
        
        // $(this).find('i').html('−');
      }

      $('.filter-categories > a').each(function(){
        var cid = $(this).data('id');
        if ($('#children-' + cid).find('a.active').length > 0) {
          $(this).addClass('show');
          $('#children-' + cid).show();
        }
      });
    });
    
    $('.filter-categories a').not('.has-child').click(function(e){
      e.preventDefault();
      $(this).toggleClass('active');
      searchJobs();
    });
    
    $('.filter-categories > a.has-child').click(function(e){
      e.preventDefault();
    
      $('.filter-categories > a.has-child').not(this).find('i').removeClass('fa-minus').addClass('fa-plus');
      $(this).find('i').toggleClass('fa-plus').toggleClass('fa-minus');
      $(this).toggleClass('show');
    
      $('.filter-categories__children').not('#children-' + $(this).data('id')).hide();

      if ($('#children-' + $(this).data('id')).is(':visible')) {
        $('#children-' + $(this).data('id')).hide();
      } else {
        $('#children-' + $(this).data('id')).show();
        $('#children-' + $(this).data('id')).find('> a').each(function(i){
          // $(this).addClass('animated gravity-' + (i % 2 + 1));
          $(this).addClass('animated fadeInDown');
        });
      }
      
    });
  }

  function initMission() {
    var mission_ball = $('.mission-ball');
    $('body').append(mission_ball);

    $(mission_ball).magnificPopup({
      items: {
        src: '#mission-modal',
        type: 'inline'
      },
      closeOnBgClick: false,
      callbacks: {
        open: function(){
          mission_ball.css('opacity', 0);
        },
        close: function(){
          mission_ball.css('opacity', 1);
        }
      }
    });

    var move_hori = 1;
    var move_vert = 1;
    var area_w = $(window).width() - $(mission_ball).width();
    var area_h = $(window).height() - $(mission_ball).height();

    if ($(mission_ball).length > 0) {
      setInterval(function(){
        var bpos = $(mission_ball).position();
        if (bpos.left + move_hori < 0 || bpos.left + move_hori > area_w) {
          move_hori *= -1;
        }
        if (bpos.top + move_vert < 0 || bpos.top + move_vert > area_h) {
          move_vert *= -1;
        }
        $(mission_ball).css('left', bpos.left + move_hori);
        $(mission_ball).css('top', bpos.top + move_vert);
      }, 20);
    }
  }
}