export default function story() {

  $('.milestone-nav a').on('click', function(event) {
    if (this.hash !== "") {
      //event.preventDefault();
      // Store hash
      var hash = this.hash;
      var pos = $(hash).offset().top;
      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
      $('html, body').animate({
        scrollTop: pos
      }, 800, function(){
        window.location.hash = hash;
        scrollActiveNav(pos);
      });
    }
  });

  function scrollActiveNav(pos) {
    var footer_height = $('#footer').height();

    $('.milestone-nav a').each(function(){
      var currLink = $(this);
      var href = currLink.attr('href');
      
      if (href.indexOf('#') != -1) {
        var hash = href.substring(href.indexOf('#'))
        var refElement = $(hash);

        if (refElement.length > 0) {
          if (refElement.position().top + footer_height - 60 <= pos && refElement.position().top + refElement.outerHeight() + footer_height > pos) {
              currLink.addClass("active");
          } else {
              currLink.removeClass("active");
          }
        }
      }
    });
  }

  $(window).on('scroll', function() {
    const pos = $(document).scrollTop();
    scrollActiveNav(pos)
  });

  scrollActiveNav($(document).scrollTop());

}
