import AOS from 'aos';

export default function common() {

  $('.aos-fade-up').attr('data-aos', 'fade-up');

  AOS.init({
    duration: 1000,
    offest: 200,
    once: true,
    anchorPlacement: 'top-bottom'
  });

  // Language
  $('#language').select2();
  $('#language').on('select2:select', function (e) {
    var data = e.params.data;
    translateLanguage(data.id);
  });

  function translateLanguage(lang) {
    doGTranslate('de|' + lang);
  }

  $('.menu-lang a').click(function(e){
    if (!$('.menu-lang').hasClass('opened')) {
      e.preventDefault();
      e.stopPropagation();
    }
    $('.menu-lang').toggleClass('opened');
  });

  $(document).click(function(){
    $('.menu-lang').removeClass('opened');
  });
}
