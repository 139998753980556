
export default function nav() {

  var lastScrollTop = 0;

  // Navigation Toggle
  $('#nav-toggle').click(function (e) {
    e.preventDefault();

    if ($('#header-main').hasClass('sticky')) {
        let header_height = $('#header-main').height();
        $('#offcanvas-nav').css('top', header_height);
    } else {
        $('#offcanvas-nav').removeAttr("style");
    }

    $(this).toggleClass('open');
    $('body').toggleClass('off-canvas');
    $('#offcanvas-overlay').fadeToggle(500);
  });

  // Sub-menu
  $('.menu-item-has-children > a').on('click', function (e) {
    e.preventDefault();
    $(this).parent().find('.sub-menu').slideToggle(500);
    $(this).parent().find('i').toggleClass('fa-arrow-up');
  });

  $('#menu-main-menu .menu-item-has-children').append('<a href="#" class="nav-sub">↓</a>');

  $('#menu-main-menu .menu-item-has-children .nav-sub').click(function(){
    var nav_sub = this;

    $(this).parent().find('.sub-menu').slideToggle(500, function(){
      $(this).toggleClass('opened');
      if ($(this).hasClass('opened')) {
        $(nav_sub).html('↑');
      } else {
        $(nav_sub).html('↓');
      }
      
    });
  });

  // Close menu on overlay click
  $('#offcanvas-overlay, #offcanvas-close').on('click', function (e) {
    e.preventDefault();
    $('#nav-toggle').removeClass('open');
    $('#offcanvas-overlay').removeClass('open');
    $('body').removeClass('off-canvas');
    $('#offcanvas-overlay').fadeOut(500);
  });

  $('#footer-nav-up, #footer-nav-up-mobile').click(function(e){
    e.preventDefault();
    $('html, body').animate({
      scrollTop: 0
    }, 800);
  });

  $(window).on('scroll', function() {
    const pos = $(document).scrollTop();
    controlHeader(pos);
  });

  controlHeader($(document).scrollTop());

  function controlHeader(pos) {

    if (pos > $('#header').outerHeight()) {
      $('#header-main, #nav-toggle').addClass('fixed');
      $('#header').css('padding-bottom', $('#header-main').outerHeight());

      if (lastScrollTop > pos) {
        $('#header-main, #nav-toggle').addClass('showDown');
        $('#main').addClass('header-show');
      } else {
        $('#header-main, #nav-toggle').removeClass('showDown');
        $('#main').removeClass('header-show');
      }

    } else {
      $('#header-main, #nav-toggle').removeClass('fixed showDown');
      $('#main').removeClass('header-show');
      $('#header').css('padding-bottom', 0);
    }

    lastScrollTop = pos;
  }
}