
export default function artist_single() {

  var audio = $('.artist-media audio');
  var audio_url = jQuery('.artist-media audio source').attr('src');
  var play_audio = false;
  
  if (audio.length) {
    if (!isMobile()) {
      
      var media_x = $('.artist-media').offset().left;
      var media_y = $('.artist-media').offset().top;
      var media_w = $('.artist-media').width();
      var media_h = $('.artist-media').height();

      $(document).mousemove(function(e){
        if (audio_url != '') {
          if (e.pageX >= media_x && e.pageX <= media_x + media_w && e.pageY >= media_y && e.pageY <= media_y + media_h) {

            $('.artist-media').addClass('play-sound');
            $('.artist-media .sound-wave').css('left', e.pageX - media_x - 50);
            $('.artist-media .sound-wave').css('top', e.pageY - media_y - 15);

          } else {

            play_audio = false;
            audio[0].pause();
            $('.artist-media').removeClass('play-sound');
            $('.artist-media .sound-wave').removeClass('running');

          }
        }
      });

      $('.artist-media').hover(function(e){
        e.preventDefault();
      });

      $('.artist-media').click(function(e){
        e.preventDefault();
        if (audio_url != '') {
          play_audio = true;
          
          audio[0].play();
          $('.artist-media .sound-wave').addClass('running');
          $('.artist-media .sound-wave').css('left', e.pageX - media_x - 50);
          $('.artist-media .sound-wave').css('top', e.pageY - media_y - 15);
        }
      });
      
    } else {
      $('.artist-media').click(function(e){
        if (audio_url != '') {
          $(this).toggleClass('play-sound');
          $('.artist-media .sound-wave').addClass('running');

          if ($(this).hasClass('play-sound')) {
            audio[0].play();
          } else {
            audio[0].pause();
          }
        }
      });
    }
  }

  initNewsRow();

  function initNewsRow() {
    $('.artists-container .news-section .list-row').not('.rendered').each(function(){

      var thumb_height = 0;
      var thumb_height_h1 = 0;
      var thumb_height_h2 = 0;

      $(this).find('.item__thumb').each(function(i){
        var height = $(this).height();
        thumb_height = thumb_height < height ? height : thumb_height; 
        if (i < 2) thumb_height_h1 = thumb_height_h1 < height ? height : thumb_height_h1; 
        if (i >= 2) thumb_height_h2 = thumb_height_h2 < height ? height : thumb_height_h2; 
      });

      if ($(window).width() >= 768) {
        $(this).find('.item__thumb').height(thumb_height);
      } else {
        $(this).find('.item__thumb:eq(0)').height(thumb_height_h1);
        $(this).find('.item__thumb:eq(1)').height(thumb_height_h1);
        $(this).find('.item__thumb:eq(2)').height(thumb_height_h2);
        $(this).find('.item__thumb:eq(3)').height(thumb_height_h2);
      }
      

      $(this).addClass('rendered');
    });
  }

  function isMobile() {
    return (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase()));
  }
}