
export default function job_single() {

  $('.job-content p').first().addClass('text-purple');

  $('.job-content p').each(function(){
    if ($(this).text() == 'Wer wir sind' || $(this).text() == 'Wie du dich bewirbst' || $(this).text() == 'Was du machst' || $(this).text() == 'Wir bieten' || $(this).text() == 'Wer du bist' || $(this).text() == 'Wir bieten dir' || $(this).text() == 'Zur Bewerbung' || $(this).text().includes('Wir freuen uns auf dich.') || $(this).text().includes('Sony Music sounds better with you.')) {
      $(this).addClass('text-purple');
    }
  });

  $('input[type=text], textarea').change(function(){
    $(this).val($.trim($(this).val()));
  });

  $('.job-action .btn-apply').click(function(e){
    e.preventDefault();

    $('html, body').animate({
      scrollTop: 0
    }, 800);

    $('.jobs-container .detail-section').hide();
    $('.jobs-container .apply-section').slideDown();
  });

  $('.attach-field a').click(function(e){
    e.preventDefault();
    $(this).parent().find('input[type=file]').click();
  });

  $('.attach-field input').on('change', function (e) {
    const file = e.target.files[0];

    if (file.size > 10485760) {
      $(this).val('');
      alert('Error: file is greater than 10mb.');
      return;
    }

    var _button = $(this).parent().find('a');

    $(_button).text(file.name);
  });

  window['job_apply_submitting'] = false;

  $('#form-apply').submit(function(){

    if (window['job_apply_submitting'] == true) {
      return false;
    }

    if ($('input[name=cf-turnstile-response]').val() == '') {
      return false;
    }

    window['job_apply_submitting'] = true;
    $('#form-apply .btn-submit').append('<i class="fal fa-spinner fa-spin"></i>');

    const form_data = new FormData(document.getElementById('form-apply'));
    $.ajax({
      url: wp.ajax_url,
      method: 'POST',
      xhrFields: { withCredentials: false },
      data: form_data,
      contentType: false,
      processData: false,
      dataType: 'json',
      beforeSend: function () {
        // $('#submit-ptx-btn').text('Loading...').prop('disabled', true);
      },
      complete: function () {
        // $('#submit-ptx-btn').text('Submit').prop('disabled', false);
        window['job_apply_submitting'] = false;
        $('#form-apply .btn-submit i').remove();
      },
      success: function (response, textStatus, jqXHR) {
        if (response.success && response.data.success) {
          $('#form-apply').hide();
          $('#apply-result').show();
        } else {
          if (response.data.error) {
            alert(response.data.error);
          } else {
            alert('An error has occurred!');
          }          
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        var err = $.parseJSON(jqXHR.responseText);
        alert(err.data.message);
      }
    });

    return false;
  });
  
}