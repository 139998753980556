require( 'owl.carousel' );
require( 'slick-carousel' );
require( 'select2' );
import 'magnific-popup';

import nav from './modules/nav';
import search from './modules/search';
import news from './modules/news';
import artists from './modules/artists';
import artist_single from './modules/artist_single';
import story from './modules/story';
import demo from './modules/demo';
import common from './modules/common';
import culture from './modules/culture';
import jobs from './modules/jobs';
import job_single from './modules/job_single';

jQuery( document ).ready(function($) {

  $('#main').css('margin-bottom', $('#footer').outerHeight());

  // init nav
  nav();

  // init search of top bar
  search();

  //init news page
  if ($('body').hasClass('page-template-news')) {
    news();
  }

  //init jobs page
  if ($('body').hasClass('page-template-jobs')) {
    jobs();
  }

  //init single job
  if ($('body').hasClass('single-job')) {
    job_single();
  }

  //init artist page
  if ($('body').hasClass('page-template-artists')) {
    artists();
  }

  //init artist page
  if ($('body').hasClass('single-artist')) {
    artist_single();
  }

  //init story page
  if ($('body').hasClass('page-template-story')) {
    story();
  }

  //init demo page
  if ($('body').hasClass('page-template-demo')) {
    demo();
  }

  //init culture page
  if ($('body').hasClass('page-template-culture')) {
    culture();
  }

  //init page
  common();
    
});
