export default function demo() {

  $(document).on('gform_confirmation_loaded', function(event, formId){
    $('.form-success').html($('.gform_confirmation_message').html());
    $('.form-success').show();
    $('.gform-container').hide();
    $('.form-desc').hide();
    

    $('html, body').animate({
      scrollTop: 0
    }, 800);
  });

}
